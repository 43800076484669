@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  behavior: smo;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul.main-menu{}
ul.main-menu > li {float: left;padding: 15px 20px;background: rgb(255, 255, 255);position: relative;}

li > ul.child-menu{width: 250px;position: absolute;border:1px solid #f2f2f2; border-radius:25%;z-index: 5555;border: 1px solid #f2f2f2;border-radius: 5px; 
  opacity: 0;visibility: hidden;transition: ease-out .35s;-moz-transition: ease-out .35s;-webkit-transition: ease-out .35s;}
ul.child-menu li{width: 100%;background-color: #fff;}
li.child:hover ul.child-menu{opacity: 1;visibility: visible;left: -250px;}
li.child:hover ul.child-menu li.child:hover ul.child-menu{opacity: 1;visibility: visible;left: -5px;top:0px;position: absolute;}
ul.child-menu li a {display: block;width: 100%;padding: 15px 20px;}
ul.child-menu li a svg{float: right;rotate: -90deg;}
li.child {position: relative;}

.collapsible {
  display: none;
  background-color: white;
  color: blue;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  font-size: 15px;font-weight: 600;
}
.product-description p {margin: 10px 0;}
.video-wrapper {
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;
}